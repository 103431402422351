body {
    background-color: #274C77;
}

.App {
    background-color: #e7ecef;
    color: #090804;
    border-radius: 0.5rem;
}

a, a:hover, a:visited, a:active {
  color: inherit;
 }
.heading {
    color: #274C77;
    text-align:center;
    padding: 1rem;
    font-size:2.5rem;
}

.item {
    background-color: #4C9F70;
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0.5rem;
    font-size: 1.5rem;
}
.incThing {
    display: inline-flex;
    align-items: center;
    align-content: center;
    margin: 0.1rem;
    padding: 0rem 1rem ;
    border-radius: 1rem;
    border: 2px solid #555555;
    /* height: 1em; */
    /* width: 10em; */
}
.typeSelector {
    padding: 1rem 1rem ;
    border-radius: 1rem;
    border: 2px solid #555555;

}
.incThing button {
    margin: 1rem;
    width: 2rem;
    height: 2rem;
}
.fullViewer {
    display: inline-flex;
    padding: 1em;
    flex-wrap:wrap;
    
}
.displayer {
    height:20em;
    width:20em;
}
.settings{
    padding: 0em 2em;
}


.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
